import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { EXECUTIVE_PRODUCTION_URL, EXECUTIVE_QA_URL, EXECUTIVE_TYPE, LOGIN_EXECUTIVE } from '@constants/business.constant';
import { ACCOUNT_NAMES } from '@constants/terminal-patients.constants';
import { MessageModalWithdrawalDisable, TitleWithdrawalDisable } from '@constants/withdrawal.constants';
import { IAccountData } from '@interfaces/account.interface';
import { IAttachedFile } from '@interfaces/terminal-file-interface';
import { DataService } from '@services/data/data.service';
import * as date from 'date-fns-tz';
import JSEncrypt from "jsencrypt";
import { format } from 'rut.js';
import { environment } from '../../../environments/environment';

const TIMEZONE = 'America/Santiago';

@Injectable()
export class Util {

  constructor(
    private readonly dataService: DataService,
    private readonly firebaseAuth: AngularFireAuth,
  ) { }

  // TODO: implement this :^)
  public calculateMinWithdrawalAmount(): number {
    return 1;
  }

  public getAccountName(accountId: string): string {
    return ACCOUNT_NAMES[accountId] || accountId;
  }

  public calculateTotalAccountsBalance(accounts: IAccountData[]): number {
    let total = 0;
    accounts.forEach((account) => {
      account.funds.forEach((fund) => {
        total += fund.amount;
      });
    });
    return total;
  }

  public redirectPage(url: string) {
    window.open(url, '_system', 'location=yes');
  }

  public formatRut(rut: string): string {
    if (!rut) return;
    let castRut = rut.substr(0, rut.length - 2);
    castRut = castRut.split('.').join('');
    return castRut;
  }

  public cleanRut(rut: string): string {
    if (!rut) return;
    return rut ? rut.replace(/[.-]/g, '').toUpperCase() : rut;
  }

  public fillRut(rut: string): string {
    return format(rut);
  }

  public prefixRutZeroes(rut: string) {
    const finalLength = 15;
    const cleanRut = rut.replace('-', '').replace('.', '').replace('.', '');
    const zeroesToAdd = finalLength - cleanRut.length;
    const base = 10;
    return (Math.pow(base, zeroesToAdd) + '').slice(1) + cleanRut;
  }

  public base64toBlob(base64Data: any, mimeType: string): Blob {
    const sliceSize = 512;
    const byteCharacters = atob(base64Data);

    const byteArrays = this.range(byteCharacters.length / sliceSize + 1)
      .map((digit) => digit * sliceSize)
      .map((offset) => byteCharacters.slice(offset, offset + sliceSize))
      .map((selectedBytes) => Array.from(selectedBytes).map((char) => char.charCodeAt(0)))
      .map((byteNumbers) => new Uint8Array(byteNumbers));

    return new Blob(byteArrays, { type: mimeType });
  }

  public async fileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.toString()
          .replace('data:', '')
          .replace(/^.+,/, '');
        resolve(base64Data);
      }
      reader.readAsDataURL(file);
    });
  }

  public formattedToday(): string {
    const pattern = 'dd-MM-yyyy';
    return date.format(new Date(), pattern, { timeZone: TIMEZONE });
  }

  public maxLegalAgeBirthDate(): string {
    const LEGAL_AGE = 18;
    const past = new Date();
    past.setFullYear(past.getFullYear() - LEGAL_AGE);
    return past.toJSON().split('T')[0];
  }

  public async executeCaptchaAction(action: string): Promise<string> {
    const recaptchaSiteKey = environment.RECAPTCHA_USE_ENTERPRISE ?
      environment.RECAPTCHA_ENTERPRISE_SITE_KEY : environment.RECAPTCHA_SITE_KEY;

    return await (environment.RECAPTCHA_USE_ENTERPRISE ?
      (window as any).grecaptcha.enterprise.execute(recaptchaSiteKey, { action }) :
      (window as any).grecaptcha.execute(recaptchaSiteKey, { action }))
  }

  private range(length: number) {
    return Array.from({ length }, (value, key) => key);
  }

  public removePrefixedZeroes(rut: string) {
    const maxRealRutLength = 9;
    const slicePosition = rut.length - maxRealRutLength;
    rut = rut.slice(slicePosition);
    rut = rut[0] === '0' ? rut.slice(1) : rut;
    return this.addRutHyphen(rut.charAt[0] === 0 ? rut.slice(1) : rut);
  }

  public addRutHyphen(rut: string) {
    const lastDigit = rut.slice(-1);
    return `${rut.slice(0, -1)}-${lastDigit}`;
  }

  public validExecutive(): boolean {
    const executivesUrls = [EXECUTIVE_PRODUCTION_URL, EXECUTIVE_QA_URL];
    if (environment.ENV === 'develop') executivesUrls.push('localhost');

    const executiveRut = this.cleanRut(this.dataService.get('executive-rut'));
    const validLoginType = this.dataService.get('login-type') === LOGIN_EXECUTIVE;
    const validExecutiveType = this.dataService.get('executive-type') === EXECUTIVE_TYPE;
    const validUrl = executivesUrls.includes(window.location.hostname);
    const validRut = this.firebaseAuth.auth.currentUser?.uid === executiveRut;

    return validRut && validLoginType && validExecutiveType && validUrl;
  }

  public openDocument(file: IAttachedFile): void {
    const blob = this.base64toBlob(file.base64Data, file.type);
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL, '_blank');
  }

  public titletWithdrawalAvailable(isFirstWithdrawalAvailable: boolean, isSecondWithdrawalAvailable: boolean = false) {
    let titleWithdrawal: string;
    titleWithdrawal = TitleWithdrawalDisable.defaultWithdrawalDisable;
    if (!isFirstWithdrawalAvailable)
      titleWithdrawal = TitleWithdrawalDisable.firstWithdrawalDisable;
    if (!isSecondWithdrawalAvailable)
      titleWithdrawal = TitleWithdrawalDisable.secondWithdrawalDisable;
    return titleWithdrawal;
  }

  public messageModalWithdrawalAvailable(isFirstWithdrawalAvailable: boolean, sSecondWithdrawalAvailable: boolean) {
    let titleWithdrawal: string;
    titleWithdrawal = MessageModalWithdrawalDisable.defaultWithdrawalDisable;
    if (!isFirstWithdrawalAvailable)
      titleWithdrawal = MessageModalWithdrawalDisable.firstWithdrawalDisable;
    if (!sSecondWithdrawalAvailable)
      titleWithdrawal = MessageModalWithdrawalDisable.secondWithdrawalDisable;
    return titleWithdrawal;
  }

  public reemplazarCaracteresEspeciales(palabra: string) {
    if(!!palabra){
      const TEXT_ONLY_REGEX = /[^a-zA-Z 0-9]+/g;
      palabra = palabra.normalize("NFD").replace(TEXT_ONLY_REGEX,"");  
    }
    
    return palabra;
  }

  public encrypt( data: any ) {
    let encrypt = new JSEncrypt;
    encrypt.setPublicKey( String(environment.LOGIN_PUBLIC_KEY_PAET) );
    const dataEncryt = encrypt.encrypt( data );
    return dataEncryt;
  }
}
